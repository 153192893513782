import React from "react";
import { ProductsListProps, TemplateElement } from "../../types";
import styled from "styled-components";
import ProductCardRenderer from "@/Renderers/ProductCardRenderer";
import ProductCard from "./ProductCard";

const ProductsList: TemplateElement<ProductsListProps> = ({ products }) => {
  return (
    <StyledContainer>
      {products?.map((product) => (
        <ProductCardRenderer
          Component={ProductCard}
          key={product?.id}
          product={product!}
          isProductList
        />
      ))}
    </StyledContainer>
  );
};

/**
 *
 * Styles
 *
 */

const StyledContainer = styled.div`
  padding: 40px 0;
  display: grid;
  /* Only using 1f breaks for small screens for long contents because 1fr = minmax(auto,1fr)
   * and item size can't be smaller than auto which is the grid item size.
   * Using minmax(0, 1fr) makes the grid element resizes min to 0 and max to 1fr and all
   * items resize proportionally
   */
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 24px;
  justify-content: center;
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding-bottom: 50px;
  }
`;

export default ProductsList;
