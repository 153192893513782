"use client";

import DefaultHomePage from "@/templates/default/pages/Home";
import { HomePageProps } from "@/templates/types";
import { StorePagesLoader } from "../page-template-loaders/store-pages-loader";

const HomePage: React.FC<HomePageProps> = (props) => {
  return (
    <StorePagesLoader>
      <DefaultHomePage {...props} />
    </StorePagesLoader>
  );
};

export default HomePage;
