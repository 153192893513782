"use client";

import { HomePageProps } from "@/templates/types";
import { formatSections } from "@/shared/utils/homeHelpers";
import HeroRenderer from "@/Renderers/HeroRenderer";
import Hero from "../elements/Hero";
import HomeAbout from "@/templates/default/elements/HomeAbout";
import { SectionTypeEnum } from "@/generated/graphql";
import WhileInViewAnimation from "@/shared/globals/WhileInViewAnimation";
import HomeContentSection from "@/templates/default/elements/HomeContentSection";
import HomeGallerySection from "../elements/HomeGallerySection";
import { EmptyState } from "@/components/UtilityComponents/EmptyState";
import { FormattedMessage } from "@/i18n/i18n-client";
import { EmptyCartIcon } from "@/assets/Icons/EmptyCartIcon";
import ProductsSectionFallback from "../components/ProductsSectionFallback";

const HomePage: React.FC<HomePageProps> = ({
  appearance,
  homepageSections,
  products,
}) => {
  const formattedSections = formatSections(homepageSections);

  return (
    <>
      <HeroRenderer Component={Hero} heroSettings={appearance?.heroSlider} />
      <HomeAbout aboutSettings={appearance?.about} />
      {formattedSections?.length ? (
        formattedSections.map((section, index) => {
          if (
            section.type === SectionTypeEnum.Text ||
            section.type === SectionTypeEnum.Embed
          ) {
            return (
              <WhileInViewAnimation key={section.id}>
                <HomeContentSection section={section} />
              </WhileInViewAnimation>
            );
          }

          return (
            <HomeGallerySection
              key={section.id}
              section={section}
              index={index}
            />
          );
        })
      ) : products?.length ? (
        <ProductsSectionFallback products={products} />
      ) : (
        <EmptyState
          title={
            <FormattedMessage
              defaultMessage="There are no products added yet"
              id="dxKBho"
            />
          }
          image={<EmptyCartIcon />}
        />
      )}
    </>
  );
};

export default HomePage;
