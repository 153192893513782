import React, { useRef, useState } from "react";
import { checkLinkTypeAndGetUrl } from "@/components/Hero/HeroBtnLink";
import { ArrowRight } from "@/assets/Icons/ArrowRight";
import type { HeroProps } from "../../types";
import { Photo } from "@/shared/globals/UiElements/Photo";
import { Heading, Text, AlignValues } from "@/components/Hero";
import { Autoplay, Navigation, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperClass from "swiper";
import useBreakPoints from "@/shared/utils/useBreakPoints";
import Flex from "@/shared/globals/UiElements/Flex";
import { useTheme } from "styled-components";
import SwiperPagination from "../components/SliderPagination/SwiperPagination";
import SwiperPaginationStyles from "../components/SliderPagination/SliderPagination.module.css";
import {
  HeroSliderFragment,
  LinkType,
  ProductCollection,
} from "@/generated/graphql";
import MotionElementWrapper from "@/shared/globals/MotionElementWrapper";
import Button from "../components/Button/Button";
import WhileInViewAnimation from "@/shared/globals/WhileInViewAnimation";
import ReactSwiperCss from "@/shared/globals/LazyElements/react-swiper";

const Hero: React.FC<HeroProps> = ({ heroSettings }) => {
  const [prevEl, setPrevEl] = useState<HTMLSpanElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLSpanElement | null>(null);
  const { isTablet } = useBreakPoints();
  const { isRTL } = useTheme();
  const heroSwiper = useRef<SwiperClass | null>(null);
  const [realActiveIndex, setRealActiveIndex] = useState(0);

  if (heroSettings?.length === 1)
    return (
      <Flex className="aspect-[16/5] max-md:aspect-[2/3] relative">
        <MainSliderSlide hero={heroSettings[0]} isTablet={isTablet} />
      </Flex>
    );

  return (
    <WhileInViewAnimation duration={0.5}>
      <Flex className="aspect-[16/5] max-md:aspect-[2/3] relative">
        {heroSettings?.length! > 1 && !isTablet && (
          <NavigationArrow
            reference={(node) => {
              if (isRTL) setPrevEl(node);
              else setNextEl(node);
            }}
          />
        )}
        <ReactSwiperCss />
        <Swiper
          navigation={{ prevEl, nextEl }}
          modules={[EffectFade, Navigation, Autoplay]}
          effect={"fade"}
          speed={1000}
          loop
          onSwiper={(swiper) => {
            heroSwiper.current = swiper;
          }}
          onSlideChange={(swiper) => {
            setRealActiveIndex(swiper.realIndex);
          }}
          autoplay={{
            delay: 4000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          className={`w-full h-full ${SwiperPaginationStyles.swiper}`}
        >
          {heroSettings?.map((hero, i) => {
            return (
              <SwiperSlide key={i} className="w-full">
                <MainSliderSlide
                  hero={hero}
                  isTablet={isTablet}
                  index={i}
                  realActiveIndex={realActiveIndex}
                />
              </SwiperSlide>
            );
          })}

          <SwiperPagination
            swiper={heroSwiper.current!}
            realActiveIndex={realActiveIndex}
            setRealActiveIndex={setRealActiveIndex}
          />
        </Swiper>

        {heroSettings?.length! > 1 && !isTablet && (
          <NavigationArrow
            reference={(node) => {
              if (isRTL) setNextEl(node);
              else setPrevEl(node);
            }}
            next
          />
        )}
      </Flex>
    </WhileInViewAnimation>
  );
};

const NavigationArrow = ({
  next,
  reference,
}: {
  next?: boolean;
  reference: (node: HTMLSpanElement | null) => void;
}) => {
  return (
    <div
      ref={reference}
      className={`absolute ${
        next ? "left-5" : "right-5"
      } top-1/2 -translate-y-[50%] flex justify-center items-center z-TOOLTIP 
      cursor-pointer w-10 aspect-square rounded-full text-gray-700
      bg-white/40 hover:bg-white transition-all duration-200`}
    >
      <ArrowRight rotate={next} />
    </div>
  );
};

interface MainSliderSlideProps {
  hero?: HeroSliderFragment | null;
  index?: number;
  realActiveIndex?: number;
  isTablet: boolean;
}

const MainSliderSlide = ({
  hero,
  index,
  isTablet,
  realActiveIndex,
}: MainSliderSlideProps) => {
  const hasOverlay =
    !!hero?.headingText ||
    !!hero?.subHeadingText ||
    !!hero?.buttons?.[0] ||
    !!hero?.buttons?.[1];
  const isButton1Exist =
    !!hero?.buttons?.[0]?.text && !!hero?.buttons?.[0]?.link?.type;
  const isButton2Exist =
    !!hero?.buttons?.[1]?.text && !!hero?.buttons?.[1]?.link?.type;
  return (
    <Flex fullWidth fullHeight alignItems="center">
      {(hero?.image || hero?.mobileImage) && (
        <Photo
          src={
            (isTablet
              ? hero?.mobileImage?.src || hero.image?.src
              : hero?.image?.src || hero?.mobileImage?.src) ||
            "/default-placeholder-image.png"
          }
          objectFit="cover"
          priority
          loading="eager"
          absolute
        />
      )}
      {hasOverlay && (
        <Flex
          className={`px-20 max-md:px-0 bg-gradient-to-t from-black/20 to-black/30 bg-opacity-20 z-TOOLTIP`}
          fullHeight
          fullWidth
          column
          alignItems={isTablet ? "center" : AlignValues[hero?.align!]}
          justifyContent="center"
          spacing="xl"
        >
          <Flex column spacing="m">
            <SlideOverlayAnimationElement
              index={index}
              realActiveIndex={realActiveIndex}
              delay={1.2}
            >
              {hero?.headingText && (
                <Heading
                  headingColor={hero?.subHeadingColor!}
                  align={isTablet ? "center" : hero?.align!}
                  noTextShadow
                >
                  {hero?.headingText}
                </Heading>
              )}
            </SlideOverlayAnimationElement>
            <SlideOverlayAnimationElement
              index={index}
              realActiveIndex={realActiveIndex}
              delay={1.3}
            >
              {hero?.subHeadingText && (
                <Text
                  noTextShadow
                  subHeadingColor={hero?.subHeadingColor!}
                  subHeadingSize={String(hero?.subHeadingSize!)}
                  align={isTablet ? "center" : hero?.align!}
                  noMargin
                >
                  {hero?.subHeadingText}
                </Text>
              )}
            </SlideOverlayAnimationElement>
          </Flex>
          <SlideOverlayAnimationElement
            index={index}
            realActiveIndex={realActiveIndex}
            delay={1.4}
          >
            {(isButton1Exist || isButton2Exist) && (
              <Flex
                flexWrap="wrap"
                justifyContent={isTablet ? "center" : AlignValues[hero?.align!]}
              >
                {isButton1Exist && (
                  <SlideButton
                    handle={
                      (hero?.buttons?.[0]?.link?.resource as ProductCollection)
                        ?.handle!
                    }
                    type={hero?.buttons?.[0]?.link?.type!}
                    url={hero?.buttons?.[0]?.link?.url!}
                    text={hero?.buttons?.[0]?.text!}
                  />
                )}
                {isButton2Exist && (
                  <SlideButton
                    handle={
                      (hero?.buttons?.[1]?.link?.resource as ProductCollection)
                        ?.handle!
                    }
                    type={hero?.buttons?.[1]?.link?.type!}
                    url={hero?.buttons?.[1]?.link?.url!}
                    text={hero?.buttons?.[1]?.text!}
                    buttonType="secondary"
                  />
                )}
              </Flex>
            )}
          </SlideOverlayAnimationElement>
        </Flex>
      )}
    </Flex>
  );
};

interface SlideOverlayAnimationElementProps {
  children: React.ReactNode;
  index?: number;
  realActiveIndex?: number;
  delay: number;
}

const SlideOverlayAnimationElement = ({
  children,
  index,
  realActiveIndex,
  delay,
}: SlideOverlayAnimationElementProps) => {
  const { isRTL } = useTheme();
  return (
    <MotionElementWrapper
      initial={{ opacity: 0, x: isRTL ? 10 : -10 }}
      animate={
        index === realActiveIndex ? { opacity: 1, x: 0 } : { opacity: 0 }
      }
      transition={{ duration: 0.4, delay }}
      fullWidth
    >
      {children}
    </MotionElementWrapper>
  );
};

interface SlideButtonProps {
  handle: string;
  type: LinkType;
  url: string;
  text: string;
  buttonType?: "primary" | "secondary";
}

const SlideButton = ({
  handle,
  type,
  url,
  text,
  buttonType = "primary",
}: SlideButtonProps) => (
  <Button
    size="lg"
    to={checkLinkTypeAndGetUrl({
      handle,
      type,
      url,
    })}
    target={type === LinkType.ExternalLink ? "_blank" : "_self"}
    type={buttonType}
  >
    {text}
  </Button>
);
export default Hero;
