import Flex from "@/shared/globals/UiElements/Flex";
import type SwiperClass from "swiper";
import MotionElementWrapper from "@/shared/globals/MotionElementWrapper";

import classes from "./SliderPagination.module.css";

interface SwiperPaginationProps {
  swiper: SwiperClass;
  realActiveIndex: number;
  setRealActiveIndex: (index: number) => void;
}

const SwiperPagination: React.FC<SwiperPaginationProps> = ({
  swiper,
  realActiveIndex,
  setRealActiveIndex,
}) => {
  const numberOfSlider = swiper?.slides?.length;

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className="absolute bottom-5 left-[50%] translate-x-[-50%] rounded-full z-TOOLTIP bg-white px-sm py-xs"
      spacing="s"
    >
      {Array.from(Array(numberOfSlider || []).keys()).map((i, index) => (
        <PaginationBullet
          key={index}
          isActive={index === realActiveIndex}
          onClick={() => {
            swiper?.slideToLoop(index);
            setRealActiveIndex(index);
          }}
        />
      ))}
    </Flex>
  );
};

interface PaginationBulletProps {
  isActive: boolean;
  onClick: () => void;
}

const PaginationBullet = ({ isActive, onClick }: PaginationBulletProps) => {
  return (
    <div onClick={onClick}>
      <MotionElementWrapper
        initial={{ width: 6, height: 6 }}
        animate={isActive ? { width: 28, height: 6 } : { width: 6, height: 6 }}
        className={`rounded-full bg-gray-300 ${
          isActive ? "" : "hover:bg-gray-500 cursor-pointer"
        } transition-all duration-200 relative overflow-hidden`}
      >
        <div
          className={`${classes.bullet} ${isActive ? classes.active : ""}`}
        />
      </MotionElementWrapper>
    </div>
  );
};

export default SwiperPagination;
